import React, { useRef, useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import { navigate } from "gatsby"
import { Tab } from "@headlessui/react"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/bundle"

import {
  CrossCountryMapDark,
  BoxDoorPortal,
  TechEnabled,
  Gauge,
  PricingTagGroup,
  BoxFast,
  AllSizes,
  GreenApproach,
  HorizontalLinePinLime,
  ReduceCosts,
  CustomerExp,
  ScaleBiz,
  Retail,
  ThreePL,
  AlertBell,
  SnapDesktop,
  HappyDesktop,
  EasyDesktop,
  SliderEasyDesktop,
  SliderEasyMobile,
  SliderHappyDesktop,
  SliderHappyMobile,
  SliderSnapDesktop,
  SliderSnapMobile,
} from "../components/svg"
import {
  Seo,
  Layout,
  Section,
  Grid,
  Container,
  Col,
  Flex,
  Overlay,
} from "../components"
import ECommerce from "../components/svg/ECommerce"
import { useWindowSize } from "../hooks"

function IndexPage() {
  const { width } = useWindowSize()

  const mobileBreakpoint = 650

  const [trackingId, setTrackingId] = useState("")
  const BtnClick = () => {
    if (trackingId.trim() != "") {
      if (navigate)
        navigate("/shipment-tracker/?tracking_number=" + trackingId, {
          state: { trackingId },
        })
    }
  }

  const data = useStaticQuery(graphql`
    query IndexQuery {
      iconReduce: file(
        relativePath: { eq: "assets/graphics/png/reduce-shipping-costs.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      iconImprove: file(
        relativePath: {
          eq: "assets/graphics/png/improve-customer-experience.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      iconScale: file(
        relativePath: { eq: "assets/graphics/png/scale-business.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      bgTruck: file(relativePath: { eq: "assets/images/truck-bg-van-6.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "assets/graphics/png/partners/shipntel-logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      logo2: file(
        relativePath: { eq: "assets/graphics/png/partners/auctane-logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      logo3: file(
        relativePath: { eq: "assets/graphics/png/partners/proship-logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      logo4: file(
        relativePath: { eq: "assets/graphics/png/partners/buku-logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      logo5: file(
        relativePath: { eq: "assets/graphics/png/partners/vizion-logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const iconReduce = data.iconReduce.childImageSharp.gatsbyImageData
  const iconImprove = data.iconImprove.childImageSharp.gatsbyImageData
  const iconScale = data.iconScale.childImageSharp.gatsbyImageData
  const bgTruck = data.bgTruck.childImageSharp.gatsbyImageData
  const logo1 = data.logo1.childImageSharp.gatsbyImageData
  const logo2 = data.logo2.childImageSharp.gatsbyImageData
  const logo3 = data.logo3.childImageSharp.gatsbyImageData
  const logo4 = data.logo4.childImageSharp.gatsbyImageData
  const logo5 = data.logo5.childImageSharp.gatsbyImageData

  const boxes = [
    {
      headline: "A Green Approach to Logistics",
      paragraph:
        "The planet needs our help. ShipX utilizes our technology platform to optimize existing routes and carrier vehicles for deliveries, whenever possible. Additionally, we invest in carbon offset programs to offset the impact of our use of fossil fuels and other resources.",
      graphic: GreenApproach,
      bgColor: "green",
      headlineColor: "green",
      textColor: "white",
    },
    {
      headline: "Simple, Predictable Pricing",
      paragraph:
        "Our rate sheets are simple, transparent, and consistent. Providing you with the ability to establish a reliable pricing strategy.",
      graphic: PricingTagGroup,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
    {
      headline: "Door-to-Door Tracking",
      paragraph:
        "Package scanning and tracking are available on our site or yours.",
      graphic: BoxDoorPortal,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
    {
      headline: "The Capacity You Need Right Now",
      paragraph:
        "Don't let capacity and surcharges limit your growth. Allow ShipX to manage your excess volume.",
      graphic: Gauge,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
    // {
    //   headline: "A Tech-Enabled Network",
    //   paragraph:
    //     "New technologies enable us to build dynamic routing with partners like never before.",
    //   graphic: TechEnabled,
    //   bgColor: "white",
    //   headlineColor: "black",
    //   textColor: "black",
    // },
    // {
    //   headline: "Up and Running — Fast",
    //   paragraph:
    //     "In most cases, we can integrate your packages into our network within a period of three days.",
    //   graphic: BoxFast,
    //   bgColor: "white",
    //   headlineColor: "black",
    //   textColor: "black",
    // },
    // {
    //   headline: "Handling Packages of All Sizes and Shapes",
    //   paragraph:
    //     "We simply deliver. Provide us with your unique or oversized items. We will ensure their prompt and efficient delivery.",
    //   graphic: AllSizes,
    //   bgColor: "white",
    //   headlineColor: "black",
    //   textColor: "black",
    // },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const benefits = [
    {
      tab: "Better Pricing",
      headline: "Reduce Your Shipping Costs",
      paragraph:
        "We strive to keep your shipping costs low by removing accessorial fees and offering predictable pricing. Our goal is to provide the most cost-efficient and reliable shipping solutions that meet your business needs.",
      graphic: ReduceCosts,
    },
    {
      tab: "Improved Customer Experience",
      headline: "Efficient Communication, Proactive Solutions",
      paragraph:
        "Our goal is to ensure complete satisfaction through effective communication and proactive problem-solving. We keep you informed throughout the entire parcel journey and address any disruptions that may occur. Trust us to handle your shipments, so you can focus on growing your business.",
      graphic: CustomerExp,
    },
    {
      tab: "Scalable Solutions",
      headline: "Partnering for Success: Growing Together",
      paragraph:
        "Expand your business capabilities with our constantly expanding Vetted Delivery Network™ (VDN). This gives you the peace of mind that as your order volume increases, ShipX is ready to execute a seamless delivery every time.",
      graphic: ScaleBiz,
    },
  ]

  const services = [
    {
      headline: "E-Commerce Delivery Solutions",
      paragraph:
        "Allow ShipX to examine your shipping data to create pricing strategies that enhance online conversion rates.",
      graphic: ECommerce,
    },
    {
      headline: "Retail Delivery Solutions",
      paragraph:
        "ShipX offers nationwide coverage and a wide range of pickup options, ensuring that your brand is well taken care of from the time it leaves the loading dock until it reaches its final destination.",
      graphic: Retail,
    },
    {
      headline: "3PL Delivery Solutions",
      paragraph:
        "ShipX can optimize efficiency and profitability for various package sizes and delivery speeds.",
      graphic: ThreePL,
    },
  ]

  const allSlides = [
    {
      desktop: <SliderSnapDesktop />,
      mobile: <SliderSnapMobile />,
    },
    {
      desktop: <SliderHappyDesktop />,
      mobile: <SliderHappyMobile />,
    },
    {
      desktop: <SliderEasyDesktop />,
      mobile: <SliderEasyMobile />,
    },
  ]

  return (
    <Layout>
      <Seo
        title="Last Mile Delivery Solutions - E-Commerce, Retail, 3PL"
        description="ShipX is a final mile parcel delivery service that helps e-commerce, retail, and 3PL shippers execute seamless, door-to-door, last mile delivery in the US."
        slug="/"
      />
      <Section
        padding="custom"
        idName="hero"
        // classes="relative text-center pt-[10rem] md:pt-[10rem] lg:pt-[15rem] xl:pt-[10rem] pb-[5rem] md:pb-[5rem] lg:pb-[20rem] xl:pb-[25rem] h-full"
        classes="
         relative
         text-center 
         pt-[5rem] 
         sm:pt-[5rem] 
         md:pt-[5rem] 
         lg:mt-[-12rem] 
         xl:mt-[-10rem] 
         2xl:mt-[-10rem] 
         pb-[10rem] 
         sm:pb-[10rem] 
         md:pb-[10rem] 
         lg:pb-[35rem] 
         xl:pb-[35rem] 
         2xl:pb-[40rem] 
         h-full"
        // classes="relative text-center pt-[10rem] lg:pt-[20rem] pb-[5rem] lg:pb-[25rem] h-full"
      >
        <div className="absolute inset-0 z-10">
          <GatsbyImage
            image={bgTruck}
            alt="Trucks"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 mix-blend-multiply bg-slate-600 lg:bg-slate-300" />
          {/* <Overlay bgColor="#cbd5e1" /> */}
        </div>
        <Container
          padding="none"
          classes="absolute bottom-0 left-0 pb-20 z-20 text-center"
        >
          <h1 className="text-4xl lg:text-6xl font-medium text-white drop-shadow-lg">
            Expect Better Delivery
            <span className="text-xl relative bottom-6 font-thin pl-1">™</span>
          </h1>
          <h2 className="text-2xl lg:text-3xl font-normal text-accent-light pt-3 lg:pt-0 drop-shadow-lg">
            Middle Mile - Last Mile
          </h2>
        </Container>
      </Section>

      <Section padding="none" classes="">
        <Link to="/blog/next-level-shipping-solutions-shipx-proship-forge-powerful-partnership/">
          <div className="flex flex-col items-center justify-center w-full pt-4 pb-6 lg:pt-8 lg:pb-10 text-white text-xl font-bold bg-gradient-to-r from-[#e4f2a7] via-[#5EC642] to-[#00B08E] relative z-20">
            <div className="flex items-center justify-center w-12 h-12 lg:w-24 lg:h-24 rounded-full -mt-10 lg:-mt-20 text-white text-xl font-bold bg-gradient-to-r from-[#82D25D] via-[#60C643] to-[#44C058] sm:from-[#70CC50] sm:via-[#5EC642] sm:to-[#4FC24E] lg:from-[#68CA4A] lg:via-[#5EC642] lg:to-[#56C449] relative z-10 mx-auto before:absolute before:h-[50%] before:left-[-100vw] before:right-[-100vw] before:bottom-0 before:z-[-99]">
              <div className="w-8 h-8 lg:w-11 lg:h-11 z-30">
                <AlertBell />
              </div>
            </div>
            <p className="text-center px-4 lg:px-0 text-base lg:text-xl">
              NEWS ALERT | ShipX and ProShip forge a powerful partnership.{" "}
              <span className="underline hover:text-secondary">Learn more</span>
              .
            </p>
          </div>
        </Link>
      </Section>

      {/* search */}

      <Section
        padding="none"
        classes="flex search-bar-g items-center justify-center"
      >
        <Container padding="global-y" classes="flex">
          <input
            type="text"
            className="px-2 lg:px-14 py-3 w-[75vw] sm:w-[50vw] md:w-80 outline-none rounded-l-3xl placeholder-black text-xs sm:text-sm lg:text-md text-center border-r-accent-light"
            placeholder="ENTER TRACKING NUMBER"
            value={trackingId}
            onChange={e => {
              setTrackingId(e.target.value)
            }}
          />
          <button
            className="px-4 text-white bg-accent-light rounded-r-3xl btn-col-search"
            onClick={() => BtnClick()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </Container>
      </Section>

      {/* <Section padding="none">
        <Container
          bgColor="bg-white"
          padding="global-md"
          classes="space-y-12 py-24"
        >
          <Container padding="none" classes="max-w-xl space-y-6 text-center">
            <h2 className="text-xl lg:text-3xl text-secondary-dark px-4 lg:px-0">
              Better Logistics Solutions for Wholesale, Retail, 3PL, and
              E-Commerce Shippers
            </h2>
            <p className="text-left lg:text-center">
              At ShipX, our mission is to revolutionize the shipping industry by
              addressing common pain points such as uncertainty in service,
              pricing, and technology. We strive to set a new standard in the
              industry by delivering exceptional service and utilizing
              cutting-edge technology to ensure a seamless delivery.
            </p>
          </Container>
          <Container padding="none" classes="pt-8">
            <Tab.Group>
              <Tab.List className="flex p-1 space-x-1 bg-secondary rounded-xl">
                {benefits.map((benefit, index) => (
                  <Tab
                    key={index}
                    className={({ selected }) =>
                      classNames(
                        "w-full p-2.5 lg:py-2.5 text-sm leading-5 font-semibold text-white rounded-lg",
                        "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-accent ring-white ring-opacity-60",
                        selected
                          ? "bg-accent-light text-secondary-dark shadow"
                          : "text-white hover:bg-white/[0.12] hover:text-white"
                      )
                    }
                  >
                    {benefit.tab}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                {benefits.map((benefit, index) => (
                  <Tab.Panel key={index}>
                    <Grid
                      padding="none"
                      classes="lg:grid-cols-2 gap-y-12 gap-x-24 pt-16"
                    >
                      <Col classes="space-y-10">
                        <div className="">
                          <benefit.graphic className="w-36 h-36 lg:w-48 lg:h-48 mx-auto" />
                        </div>
                      </Col>
                      <Col classes="space-y-10">
                        <div className="text-left space-y-6">
                          <h3 className="text-secondary-dark text-center lg:text-left text-xl">
                            {benefit.headline}
                          </h3>
                          <p className="">{benefit.paragraph} </p>
                        </div>
                      </Col>
                    </Grid>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </Container>
        </Container>
      </Section> */}

      {/* <Section padding="sm">
        <Container padding="global-md">
          <div className="bg-gradient-to-r from-gray-800/95 via-gray-600/95 to-gray-800/95 py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <h2 className="text-center text-lg font-semibold leading-8 text-accent-light">
                Partners with the world’s most innovative teams
              </h2>
              <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <GatsbyImage
                  image={logo1}
                  alt="logo"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                <GatsbyImage
                  image={logo2}
                  alt="logo"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                <GatsbyImage
                  image={logo3}
                  alt="logo"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                <GatsbyImage
                  image={logo4}
                  alt="logo"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
                <GatsbyImage
                  image={logo5}
                  alt="logo"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                />
              </div>
            </div>
          </div>
        </Container>
      </Section> */}

      {/* <Section padding="none" classes="cta-bg">
        <Container padding="global-md" classes="text-center py-24 space-y-12">
          <h2 className="text-accent-light text-center mx-auto p">
            Turnkey Delivery Solutions
          </h2>
          <Grid classes="lg:grid-cols-2 gap-8 space-y-8 lg:space-y-0">
            <Col>
              <div className="mx-auto space-y-6">
                <ul className="text-left w-fit flex flex-col justify-center mx-auto space-y-4 lg:space-y-2 list-indent">
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Parcel Delivery to 45 States and 18,000 Zip Codes
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Middle Mile Services Available in 48 States and Canada
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    100+ Years of Combined Experience
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Redundant and Reliable Parcel Delivery Network
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Scalability to Enable Growth
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Simple, Predictable Pricing
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Effortless Onboarding Experience
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Middle and Final Mile Services Available
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Integrations with Leading TMS (Transport Management System)
                    Platforms
                  </li>
                  <li className="text-white">
                    <i className="fas fa-check text-accent-light mr-3" />
                    Comprehensive Parcel Tracking and Monitoring Capabilities
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <div className="space-y-6">
                <p className="text-center text-white text-xl lg:text-3xl font-light">
                  Discover how our cutting-edge technology and cost efficient
                  solutions can improve your shipping experience.
                </p>
                <div className="pt-8 mx-auto flex justify-center">
                  <Link
                    to="/contact/"
                    className="px-10 py-6 text-secondary font-bold lg:text-2xl sm:text-2xl rounded-full bg-accent-light hover:bg-accent-dark hover:text-white transition duration-150 ease-in-out"
                  >
                    Schedule a meeting
                  </Link>
                </div>
              </div>
            </Col>
          </Grid>
        </Container>
      </Section> */}

      <Section padding="none">
        <Container
          bgColor="bg-white"
          padding="global-md"
          classes="space-y-12 py-24"
        >
          <Container padding="none" classes="max-w-xl space-y-6 text-center">
            <h2 className="text-secondary-dark">
              Focused, Frictionless Delivery Services
            </h2>
            <p>
              ShipX is a trusted provider for major e-commerce businesses,
              retailers, and 3PLs, thanks to our Vetted Delivery Network™ (VDN)
              that allows us to execute thousands of deliveries every day. We
              carefully screen our delivery providers to ensure the quality,
              reliability, and safety of our service. With our VDN, we offer
              unparalleled delivery capacity, distinguishing ourselves as the
              preferred choice in the industry due to our meticulous attention
              to detail.
            </p>
          </Container>
          <Grid classes="lg:grid-cols-3 gap-20">
            {services.map((service, index) => (
              <Col key={index} classes="space-y-4">
                <div className="">
                  <service.graphic className="w-36 h-36 mx-auto" />
                </div>
                <div className="text-center space-y-2">
                  <h3 className="text-secondary-dark text-xl px-6">
                    {service.headline}
                  </h3>
                  <p className="text-center">{service.paragraph}</p>
                </div>
              </Col>
            ))}
          </Grid>
        </Container>
      </Section>

      <Section padding="sm">
        <Container padding="global-md" classes="space-y-12">
          {boxes.map((box, index) => (
            <div key={index}>
              {index % 2 === 0 ? (
                <Grid
                  classes={
                    box.bgColor === "white"
                      ? "bg-white lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[550px] lg:h-[400px]"
                      : "bg-accent-greenApproach lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[800px] lg:h-[400px]"
                  }
                >
                  <Col classes="order-2 lg:order-1 m-auto">
                    <box.graphic className="w-full h-full max-h-60 lg:max-h-64" />
                  </Col>
                  <Col classes="order-1 lg:order-2 space-y-4 m-auto">
                    <h2
                      className={
                        box.headlineColor === "black"
                          ? "text-secondary-dark"
                          : "text-accent-light"
                      }
                    >
                      {box.headline}
                    </h2>
                    <p
                      className={
                        box.textColor === "black"
                          ? "text-secondary-dark font-medium"
                          : "text-white font-medium"
                      }
                    >
                      {box.paragraph}
                    </p>
                  </Col>
                </Grid>
              ) : (
                <Grid
                  classes={
                    box.bgColor === "white"
                      ? "bg-white lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[550px] lg:h-[400px]"
                      : "bg-accent-greenApproach lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[800px] lg:h-[400px]"
                  }
                >
                  <Col classes="order-1 lg:order-1 space-y-4 m-auto">
                    <h2
                      className={
                        box.headlineColor === "black"
                          ? "text-secondary-dark"
                          : "text-accent-light"
                      }
                    >
                      {box.headline}
                    </h2>
                    <p
                      className={
                        box.textColor === "black"
                          ? "text-secondary-dark font-medium"
                          : "text-white font-medium"
                      }
                    >
                      {box.paragraph}
                    </p>
                  </Col>
                  <Col classes="order-2 lg:order-2 m-auto">
                    <box.graphic className="w-full h-full max-h-60 lg:max-h-64" />
                  </Col>
                </Grid>
              )}
            </div>
          ))}
        </Container>
      </Section>

      <Section padding="none">
        <Container
          padding="none"
          bgColor="bg-secondary"
          classes="pt-24 pb-24 lg:pt-24 lg:pb-0 lg:px-0 text-center space-y-24"
        >
          <p className="text-white font-bold max-w-lg mx-auto text-2xl lg:text-3xl px-4">
            Let ShipX create a customized delivery solution for you.
          </p>
          <div>
            <Link
              to="/contact/"
              className="px-10 py-6 text-secondary font-bold lg:text-3xl sm:text-2xl rounded-full bg-accent-light hover:bg-accent-dark hover:text-white transition duration-150 ease-in-out"
            >
              Schedule a meeting
            </Link>
          </div>
          <div className="pl-6 flex justify-end">
            <HorizontalLinePinLime className="w-full lg:w-1/3 relative lg:bottom-[150px] xl:bottom-[150px] 2xl:bottom-[150px]" />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
